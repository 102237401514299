import React from 'react'
import Dashboard from 'v2/spas/dashboard/pages/view.tsx'
import Login from 'spas/Login/Login'
import { MainWrapper } from 'functional-components'

function MortgageBrokerRootPath(currentUser) {
  if (!currentUser.id) {
    return <Login />
  } else {
    return (
      <MainWrapper>
        <Dashboard />
      </MainWrapper>
    )
  }
}

export default MortgageBrokerRootPath
