import update from 'immutability-helper'
import { FETCH_CASE_REQUEST } from 'v2/spas/account/cases/redux/action-types.tsx'
import { FETCH_CASE_SUCCESS } from 'v2/spas/account/cases/redux/action-types.tsx'
import { FETCH_CASE_FAILURE } from 'v2/spas/account/cases/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  case: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CASE_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_CASE_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_CASE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        case: {
          $set: action.payload.case,
        }
      })
    }

    default:
      return state
  }
}