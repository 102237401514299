import {combineReducers} from 'redux'
import authentication from './authentication'
import profile from './profile'
import accountCases from 'v2/spas/account/cases/redux/reducers/index.tsx'
import accountCase from 'v2/spas/account/cases/redux/reducers/view.tsx'
import caseProperty from 'v2/spas/case/properties/redux/reducers/view.tsx'
import accountUserActivityFeedItems from 'v2/spas/account-user/activity-feed-items/redux/reducers/index.tsx'
import workflow from 'v2/spas/workflow/redux/reducers/view.tsx'

const rootReducer = combineReducers({
  authentication,
  profile,
  accountCases,
  accountCase,
  caseProperty,
  accountUserActivityFeedItems,
  workflow

})

export default rootReducer
