import React from 'react'
import { CardGrid } from 'grids'
import Col from 'react-bootstrap/Col'
import { GridSectionHeading } from 'headings'
import { AssociationCard } from 'cards'
import { Archive } from 'react-bootstrap-icons';
import { useCurrentAccount } from 'functions'

function Grid() {

  let currentAccount = useCurrentAccount()

  let solicitorAccount = currentAccount.solicitorAccount || {}
  let solicitorAccountCounters = solicitorAccount.counters || {}
  let casesCounter = solicitorAccountCounters.cases || {}

  return (
    <CardGrid>
      <Col lg='12'>
        <GridSectionHeading
          heading='What do you want to do?'
          description='Here are some helpful shortcuts, to point you in the right direction'
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/accounts/${currentAccount.id}/cases`}
          heading='Cases'
          icon={<Archive size={50} />}
          description='Review your outstanding cases'
          count={casesCounter.active}
        />
      </Col>
    </CardGrid>
  )
}

export default Grid