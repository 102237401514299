import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useEffect, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import {SolicitorTheme} from 'themes'
import {GlobalStyles} from 'themes'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { HelmetProvider } from "react-helmet-async";
import {Spinner} from 'functional-components'
import { loadStacked } from 'redux/actions/profile/loadStacked'
import { logout } from 'redux/actions/authentication/logout'
import { session } from 'redux/actions/authentication/session'
import { useInterval } from 'functions'
import { MortgageBrokerRootPath } from 'functional-components'
import {FetchResource} from 'functional-components'
import {ModalButton} from 'buttons'
import {PageNotFound} from 'pages';
import {ScrollToTop} from 'functional-components'
import {Snackbar} from 'functional-components';
import { ZendeskWidget, PrivateRoute } from 'functional-components';
import {WarningModal} from 'modals'

const Login = lazy(() => import('spas/Login/Login.js'));
const SignInWithMagicLink = lazy(() => import('spas/SignInWithMagicLink/SignInWithMagicLink.tsx'));
const AccountCases = lazy(() => import('v2/spas/account/cases/pages/index.tsx'));
const AccountCase = lazy(() => import('v2/spas/account/cases/pages/view.tsx'));
const CaseProperty = lazy(() => import('v2/spas/case/properties/pages/view.tsx'));
const AccountUserActivityFeedItems = lazy(() => import('v2/spas/account-user/activity-feed-items/pages/index.tsx'));
const AccountUserActivityFeedItemsArchive = lazy(() => import('v2/spas/account-user/activity-feed-items/pages/archive.tsx'));
const Workflow = lazy(() => import('v2/spas/workflow/pages/view.tsx'));

function initialiseSentry() {
  if (window.location.href.indexOf("stacked.group") > -1) {
    Sentry.init({
      dsn: "https://d422f667e28646289be0cd3d3c5ac859@o4504261359894528.ingest.sentry.io/4504261362909184",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
}

initialiseSentry()

const App = ({
  currentUser,
  currentAccount,
  currentEmailIdentity,
  policy,
  loadStackedStatus,
  loadStackedError,
  sessionStatus,
  sessionError,
  sessionExpiring,
  loadStacked,
  session,
  logout,
}) => {
  const logOut = () => {
    logout(handleLogOut);
  };

  const handleLogOut = response => {
    if (response.status === 'success') {
      window.location.href = '/login';
    } else if (response.status === 'error') {
      window.location.reload();
    }
  };

  useInterval(async () => {
    session();
  }, 600000); // Check every 10 minutes
  
  useEffect(() => {
    loadStacked();
  }, []);

  return (
    <HelmetProvider>
      <SolicitorTheme>
        <Snackbar>
          <GlobalStyles />
          <Router>
            <Suspense fallback={<Spinner />}>
              <ZendeskWidget />
              <ScrollToTop />
              <FetchResource status={loadStackedStatus} error={loadStackedError}>
                <WarningModal
                  show={sessionExpiring}
                  heading="Your session is about to expire!">
                  <ModalButton
                    text="Please login again"
                    backgroundColor={props => props.theme.colors.offWhite}
                    color={props => props.theme.colors.black}
                    handleClick={logOut}
                  />
                </WarningModal>
                <WarningModal
                  show={currentUser.signUpStatus === 'confirm_email'}
                  heading="You have not confirmed your email address yet! Please check your emails and click the link to confirm your email address!">
                  <ModalButton
                    text="Continue"
                    backgroundColor={props => props.theme.colors.offWhite}
                    color={props => props.theme.colors.black}
                    handleClick={logOut}
                  />
                </WarningModal>
                <Switch>
                  <Route exact path="/">
                    {MortgageBrokerRootPath(currentUser, currentEmailIdentity, currentAccount)}
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/sign-in-with-magic-link/:token">
                    <SignInWithMagicLink />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/cases"
                    authorised={true}
                  >
                    <AccountCases />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/cases/:caseId"
                    authorised={true}>
                    <AccountCase />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/cases/:caseId/properties/:propertyId"
                    authorised={true}>
                    <CaseProperty />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/activity-feed-items"
                    authorised={true}>
                    <AccountUserActivityFeedItems />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/archived-activity-feed-items"
                    authorised={true}>
                    <AccountUserActivityFeedItemsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/workflows/:workflowId"
                    authorised={true}>
                    <Workflow />
                  </PrivateRoute>
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </FetchResource>
            </Suspense>
          </Router>
        </Snackbar>
      </SolicitorTheme>
    </HelmetProvider>
  );
};

const mapStateToProps = state => ({
  loadStackedStatus: state.profile.loadStackedStatus,
  loadStackedError: state.profile.loadStackedError,
  currentUser: state.profile.currentUser,
  currentEmailIdentity: state.profile.currentEmailIdentity,
  currentAccount: state.profile.currentAccount,
  policy: state.profile.policy,
  sessionStatus: state.authentication.sessionStatus,
  sessionError: state.authentication.sessionError,
  sessionExpiring: state.authentication.sessionExpiring,
});

const mapDispatchToProps = {
  loadStacked: loadStacked,
  session: session,
  logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);